<template>
  <div class="id-card">
    <component :is="`style`">
      .card-one-wrapper {
      font-family: "Oxygen",
      sans-serif;
      }
      .lenon-id-card-three {
      width: 500px;
      height: 300px;
      background-color: {{ customization.alt_bg_color }};
      /* background-color: rgb(92, 223, 92); */
      margin: 5px;
      position: relative;
      padding: 0px !important;
      /* transform: rotate(90deg); */
      overflow: hidden;
      border: 1px dashed black;
      }
      .lenon-card-rectangle {
      width: 500px;
      height: 230px;
      position: absolute;
      top: 0px;
      /* border-bottom-right-radius: 30px; */
      border-bottom-left-radius: 30px;
      }
      #barcode {
      /* height: 60px; */
      /* background-color: black; */
      position: absolute;
      bottom: 0px;
      /* left: 50px;
      right: 50px; */
      /* left: 0; */
      right: 0px;
      /* top: 100px; */
      /* margin-left: auto;
      margin-right: auto; */
      }
      .profile {
      height: 140px;
      width: 140px;
      position: absolute;
      border-radius: 30px;
      object-fit: contain;
      top: 105px;
      right: 20px;
      }
      .school-logo img {
      position: absolute;
      left: 10px;
      top: 10px;
      /* top: 10px; */
      /* padding: 5px; */
      /* background-color: white; */
      }
      .school-name {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      text-align: center;
      color: {{ customization.text_color }};
      font-size: 20px;
      text-transform: uppercase;
      }
      .school-name p {
      margin: 0px !important;
      padding: 5px !important;
      font-size: 16px !important;
      font-weight: bolder;
      }
      .student-information {
      position: absolute;
      right: 180px;
      top: 120px;
      text-align: left;
      color: {{ customization.text_color }};
      }
      .student-information .name {
      font-size: 25px;
      font-weight: bolder;
      text-decoration: underline;
      }
      .student-information .student-id {
      font-size: 20px;
      font-weight: 400;
      }
      .student-information .dob {
      font-size: 17px;
      font-weight: 200;
      }
      .issued-at {
      position: absolute;
      bottom: 7px;
      left: 10px;
      font-size: 16px;
      text-decoration: underline;
      color: {{ customization.issued_date_text_color }};
      }
    </component>
    <div
      style="display: flex; flex-direction: column; align-items: center"
      class="card-one-wrapper"
    >
      <div class="lenon-id-card-three">
        <div
          class="lenon-card-rectangle"
          :style="customization.gradient?
            `background-image: linear-gradient(${customization.first_color}, ${customization.second_color};`
            :`background-color:${customization.bg_color}`"
        >
          <div class="school-name">
            <strong>{{ schoolName }}</strong>
            <p>STUDENT CARD</p>
          </div>
          <div
            v-if="customization.selected.includes('logo') && schoolLogo"
            class="school-logo"
          >
            <img
              width="50"
              height="50"
              style="border-radius: 50px"
              :src="schoolLogo"
              alt=""
            >
          </div>
          <div class="profile">
            <img
              width="140"
              height="140"
              :style="`border-radius: 30px; border: 10px solid ${customization.alt_bg_color}`"
              src="https://cambodiaict.net/wp-content/uploads/2019/12/computer-icons-user-profile-google-account-photos-icon-account.jpg"
              alt=""
            >
          </div>
          <div class="student-information">
            <div class="name">
              Rita Naana Ora
            </div>
            <div
              v-if="customization.selected.includes('id')"
              class="student-id"
            >
              S220010822
            </div>
            <div
              v-if="customization.selected.includes('dob')"
              class="dob"
            >
              <strong>DoB</strong> Aug 20, 2010
            </div>
            <div
              v-if="customization.selected.includes('class')"
              class="dob"
            >
              <strong>Class</strong> JHS 1
            </div>
            <div
              v-if="customization.selected.includes('phone')"
              class="dob"
            >
              <strong>E. Phone</strong> 0201001001
            </div>
          </div>
        </div>
        <div class="issued-at">
          <strong>Issued on</strong> 20th Jan 2021
        </div>
        <svg id="barcode" />
      </div>
    </div>
  </div>

</template>

<script>
const JsBarcode = require('jsbarcode')

export default {
  name: 'Card1',
  props: {
    customization: { type: Object, default: () => {} },
  },
  computed: {
    schoolName() {
      return this.$store.getters['auth/schoolName']
    },
    schoolLogo() {
      return this.$store.getters['auth/schoolLogo']
    },
    barcodeColor() {
      return this.customization.barcode_color
    },
  },
  watch: {
    barcodeColor(color) {
      JsBarcode('#barcode', '1234567891', {
        width: 3,
        height: 35,
        displayValue: false,
        background: ' rgba(255, 255, 255, 0);',
        lineColor: color,
      })
    },
  },
  mounted() {
    JsBarcode('#barcode', '1234567891', {
      width: 3,
      height: 35,
      displayValue: false,
      background: ' rgba(255, 255, 255, 0);',
      lineColor: this.customization.barcode_color,
    })
  },
}
</script>
