<template>
  <b-card
    title="Customize ID"
  >
    <div class="d-flex justify-content-center">
      <div class="d-flex flex-column">
        <div id="id-card">
          <card1 :customization="customization" />
        </div>
        <div class="mt-1">
          <div class="mb-1">
            <strong>Card Information</strong>
          </div>
          <b-form-group>
            <b-form-checkbox-group
              v-model="customization.selected"
              :options="options"
            />
          </b-form-group>
        </div>
        <div>
          <div class="mb-1">
            <strong>Theme</strong>
          </div>
          <div class="d-flex">
            <div
              v-if="customization.gradient"
              class="d-flex align-items-center"
            >
              <input
                id="first-color"
                v-model="customization.first_color"
                type="color"
              >
              <span class="px-1"><label for="first-color">First Color</label></span>
            </div>
            <div
              v-if="customization.gradient"
              class="d-flex align-items-center"
            >
              <input
                id="bg-2-color"
                v-model="customization.second_color"
                type="color"
              >
              <span class="px-1"><label for="bg-2-color">Second Color</label></span>
            </div>
            <div
              v-if="!customization.gradient"
              class="d-flex align-items-center"
            >
              <input
                id="bg-color"
                v-model="customization.bg_color"
                type="color"
              >
              <span class="px-1"><label for="bg-color">Background</label></span>
            </div>
            <div
              class="d-flex align-items-center"
            >
              <input
                id="text-color"
                v-model="customization.text_color"
                type="color"
              >
              <span class="px-1"><label for="text-color">Text</label></span>
            </div>

            <b-form-checkbox
              v-model="customization.gradient"
              class="custom-control-primary"
            >
              Gradient
            </b-form-checkbox>
          </div>
          <div class="d-flex mt-1">
            <div
              class="d-flex align-items-center"
            >
              <input
                id="alt-bg-color"
                v-model="customization.alt_bg_color"
                type="color"
              >
              <span class="px-1"><label for="alt-bg-color">Alt Background</label></span>
            </div>
            <div
              class="d-flex align-items-center"
            >
              <input
                id="issued-at-color"
                v-model="customization.issued_date_text_color"
                type="color"
              >
              <span class="px-1"><label for="issued-at-color">Issued At</label></span>
            </div>
            <div
              class="d-flex align-items-center"
            >
              <input
                id="barcode-color"
                v-model="customization.barcode_color"
                type="color"
              >
              <span class="px-1"><label for="barcode-color">Barcode</label></span>
            </div>
          </div>
        </div>
        <div class="mt-3">

          <lenon-button
            class="float-right ml-1"
            label="Save"
            :disabled="customizationLoading"
            :loading="customizationLoading"
            @onClick="saveCustomization()"
          />
          <!--          <lenon-button-->
          <!--            class="float-right"-->
          <!--            label="Print Sample"-->
          <!--            icon="PrinterIcon"-->
          <!--            @onClick="print()"-->
          <!--          />-->
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import Card1 from '@/views/admin/academics/student-id-cards/Card1.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { CUSTOMIZATIONS_Q } from '@/graphql/queries'
import { UPDATE_CUSTOMIZATION_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'

export default {
  components: {
    LenonButton,
    Card1,
    BCard,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  mixins: [showToast],
  data() {
    return {
      customizationLoading: false,
      forceCustomizationUpdate: false,
      customization: {
        selected: [],
        gradient: true,
        first_color: null,
        second_color: null,
        bg_color: null,
        alt_bg_color: null,
        text_color: null,
        issued_date_text_color: null,
        barcode_color: null,
      },
      options: [
        {
          text: 'Logo',
          value: 'logo',
        },
        {
          text: 'Student ID',
          value: 'id',
        },
        {
          text: 'Emergency Phone',
          value: 'phone',
        },
        {
          text: 'Class',
          value: 'class',
        },
        {
          text: 'Dob',
          value: 'dob',
        },
      ],
    }
  },
  computed: {
    idCardCustomization() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.forceCustomizationUpdate = !this.forceCustomizationUpdate
      return this.$store.getters['studentId/studentIdCustomization']
    },
    selectedOptions() {
      return this.customization.selected
    },
  },
  watch: {
    selectedOptions(selected) {
      // we do not want a lot of information on id card
      // make sure only two options are selected excluding logo
      // eslint-disable-next-line no-param-reassign
      selected = selected.filter(s => s !== 'logo')
      if (selected.length > 2) {
        const first = selected[0]
        this.customization.selected = this.customization.selected.filter(s => s !== first)
      }
    },
    idCardCustomization(cs) {
      const { customization } = cs
      let selected = []
      selected = customization.show_logo ? [...selected, 'logo'] : selected
      selected = customization.show_student_id ? [...selected, 'id'] : selected
      selected = customization.show_emergency_phone ? [...selected, 'phone'] : selected
      selected = customization.show_class ? [...selected, 'class'] : selected
      selected = customization.show_dob ? [...selected, 'dob'] : selected
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.customization.selected = selected
      this.customization.gradient = customization.is_gradient
      this.customization.first_color = customization.first_color
      this.customization.second_color = customization.second_color
      this.customization.bg_color = customization.bg_color
      this.customization.alt_bg_color = customization.alt_bg_color
      this.customization.text_color = customization.text_color
      this.customization.issued_date_text_color = customization.issued_date_text_color
      this.customization.barcode_color = customization.barcode_color
    },
  },
  mounted() {
    this.forceCustomizationUpdate = !this.forceCustomizationUpdate
    this.getCustomizations()
  },
  methods: {
    async print() {
      await this.$htmlToPaper('id-card')
    },
    saveCustomization() {
      this.customizationLoading = true
      const customization = {
        id: this.idCardCustomization.id,
        type: this.idCardCustomization.type,
        document_version: this.idCardCustomization.document_version,
        customization: {
          show_logo: this.customization.selected.includes('logo'),
          show_student_id: this.customization.selected.includes('id'),
          show_emergency_phone: this.customization.selected.includes('phone'),
          show_class: this.customization.selected.includes('class'),
          show_dob: this.customization.selected.includes('dob'),
          is_gradient: this.customization.gradient,
          first_color: this.customization.first_color,
          second_color: this.customization.second_color,
          bg_color: this.customization.bg_color,
          alt_bg_color: this.customization.alt_bg_color,
          text_color: this.customization.text_color,
          issued_date_text_color: this.customization.issued_date_text_color,
          barcode_color: this.customization.barcode_color,
        },
      }
      this.$apollo.mutate({
        mutation: UPDATE_CUSTOMIZATION_M,
        variables: { input: customization },
      }).then(res => {
        this.showSuccess('Saved customization successfully')
        this.customizationLoading = false
        this.$store.commit('studentId/updateCustomization', res.data.updateCustomization)
      }).catch(err => {
        console.log(err)
        this.customizationLoading = false
        this.showError('Failed to save customization')
      })
    },
    getCustomizations() {
      this.$apollo.query({ query: CUSTOMIZATIONS_Q })
        .then(res => {
          this.$store.commit('studentId/setCustomizations', res.data.customizations)
        })
    },
  },
}
</script>
